/*
 * @Descripttion:
 * @version:
 * @Author: hutian
 * @Date: 2021-03-15 16:47:21
 * @LastEditors: hutian
 * @LastEditTime: 2021-03-15 17:08:06
 */
/**
* api接口统一管理
*/
import { axios } from '@/utils/request'

export const listFirmPermission = params => axios({
    url: '/api/base/firm/firmPermission/list',
    method: 'get',
    params,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const addFirmPermission = params => axios({
    url: '/api/base/firm/firmPermission/add',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const editFirmPermission = params => axios({
    url: '/api/base/firm/firmPermission/edit',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const delFirmPermission = params => axios({
    url:'/api/base/firm/firmPermission/del/' + params,
    method: 'post',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const selectByIdFirmPermission = params => axios({
    url: '/api/base/firm/firmPermission/selectById/' + params,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})
